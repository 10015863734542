import React, { Component } from 'react';

import flower1 from '../../img/left-flower.png';
import flower2 from '../../img/right-flower.png';
import Modal from '../modal';
import { CheckCircle, Info } from 'react-feather';
import moment from 'moment';
import AgePicker from '../agePicker';

class PledgeForm extends Component {
  state = {
    inputs: {
      email: '',
      phone: '',
      location: '',
      whatsapp: 1,
      sms: 1,
    },
    showOtherLocation: false,
    pledged: false,
    showAgeLimitPopup: false,
  };
  render() {
    return (
      <>
        <section className=' magazine position-relative pledge-form-section'>
          <div className='py-5 text-center container'>
            <div className='row align-items-center mt-5 mb-md-5 py-md-4'>
              <div className='col-md-6 '>
                <h1 className='intro-title mb-4 m-0 d-inline-block'>
                  Take Your <br /> Pledge Today
                </h1>
              </div>
              <div className='col-md-6'>
                <p className=' text-left desc-text'>
                  By signing up, you're joining the SKY sistahood which is made
                  up of girls who have decided to be true to themselves and make
                  the choices that are right for them! Fill in your information
                  and click the pink button below.
                </p>
              </div>
            </div>

            <form
              action=''
              onSubmit={(e) => {
                e.preventDefault();
                // console.log(this.state.inputs);

                this.setState({ pledged: true });
                this.join();
              }}
              className='px-3 pledge-form row'>
              <div className='row col-md-6 justify-content-end'>
                <div className='my-3 col-md-6'>
                  <input
                    placeholder='First Name'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.first_name = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    type='text'
                    className='form-control'
                  />
                </div>
                <div className='my-3 col-md-6'>
                  <input
                    placeholder='Last Name'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.last_name = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    type='text'
                    className='form-control'
                  />
                </div>
                <div className='my-3 col-md-6'>
                  <div className='text-left pb-2 pl-3'>
                    <small>
                      <small className='font-weight-bold'> BIRTH DATE</small>
                    </small>
                  </div>
                  <div className='date-input'>
                    <AgePicker
                      onChange={(date) => {
                        let { inputs } = this.state;
                        inputs.dob = date;
                        console.log(inputs);
                        this.setState({ inputs, errors: {} });
                      }}
                      onBlur={(date) => {
                        let { inputs } = this.state;
                        inputs.dob = date;
                        if (
                          moment('2021-07-01').diff(inputs.dob, 'years') > 19 ||
                          moment('2021-07-01').diff(inputs.dob, 'years') < 13
                        ) {
                          this.setState({ showAgeLimitPopup: true });
                        } else {
                          this.setState({ showAgeLimitPopup: false });
                        }
                      }}></AgePicker>
                  </div>

                  {/* <input
                    placeholder='Date'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.dob = e.target.value;
                      this.setState({ inputs });
                    }}
                    onBlur={(date) => {

                      if (
                        moment().diff(inputs.dob, 'years') > 18 ||
                        moment().diff(inputs.dob, 'years') < 13
                      ) {
                        this.setState({ showAgeLimitPopup: true });
                      } else {
                        this.setState({ showAgeLimitPopup: false });
                      }
                    }}
                    required={true}
                    type='date'
                    className='form-control'
                  /> */}
                </div>

                <div className='my-3 col-md-12'>
                  <input
                    placeholder='Email'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.email = e.target.value;
                      this.setState({ inputs });
                    }}
                    // required={true}
                    type='email'
                    className='form-control'
                  />
                </div>

                <div className='my-3 col-md-6'>
                  <input
                    placeholder='Phone Number'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.phone = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    type='tel'
                    className='form-control'
                  />
                </div>

                <div className='my-3 col-md-6'>
                  {this.state.showOtherLocation ? (
                    <input
                      placeholder='Where do you live'
                      onChange={(e) => {
                        let { inputs } = this.state;
                        inputs.location = e.target.value;
                        this.setState({ inputs });
                      }}
                      required={true}
                      type='address'
                      className='form-control'
                    />
                  ) : (
                    <select
                      name=''
                      id=''
                      onChange={(e) => {
                        // console.log(e.target.value);
                        if (e.target.value === 'other') {
                          this.setState({ showOtherLocation: true });
                          setTimeout(() => {}, 0);
                          return false;
                        }

                        let { inputs } = this.state;
                        inputs.location = e.target.value;
                        this.setState({ inputs });
                      }}
                      className='form-control'
                      required={true}>
                      <option value='' selected hidden>
                        Where do you live
                      </option>
                      <option value='Westlands'>Westlands</option>
                      <option value='Kilimani'>Kilimani</option>
                      <option value='Langata'>Langata</option>
                      <option value='Buruburu'>Buruburu</option>
                      <option value='South C'>South C</option>
                      <option value='other'>Other</option>
                    </select>
                  )}
                </div>
              </div>
              <div className='row col-md-6'>
                <div className='col-md-6 text-left'>
                  <label className='mb-2'>
                    <small>
                      <small className='font-weight-bold'>
                        WHAT'S YOUR THING?
                      </small>
                    </small>
                  </label>
                  <textarea
                    placeholder='1.'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.thing1 = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    className='form-control textarea'
                  />
                  <textarea
                    placeholder='2.'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.thing2 = e.target.value;
                      this.setState({ inputs });
                    }}
                    // required={true}

                    className='form-control textarea'
                  />
                </div>
                <div className='col-md-6 text-left'>
                  <label className='mb-2'>
                    <small>
                      <small className='font-weight-bold'>
                        WHAT'S NOT YOUR THING?
                      </small>
                    </small>
                  </label>
                  <textarea
                    placeholder='1.'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.not_thing1 = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    className='form-control textarea'
                  />
                  <textarea
                    placeholder='2.'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.not_thing2 = e.target.value;
                      this.setState({ inputs });
                    }}
                    // required={true}

                    className='form-control textarea'
                  />
                </div>
                <div className='my-3 col-md-8'>
                  {this.state.showOtherInfo ? (
                    <input
                      placeholder='How did you hear of SKY?'
                      onChange={(e) => {
                        let { inputs } = this.state;
                        inputs.info_source = e.target.value;
                        this.setState({ inputs });
                      }}
                      required={true}
                      type='address'
                      className='form-control'
                    />
                  ) : (
                    <select
                      name=''
                      id=''
                      onChange={(e) => {
                        // console.log(e.target.value);
                        if (e.target.value === 'other') {
                          this.setState({ showOtherInfo: true });
                          setTimeout(() => {}, 0);
                          return false;
                        }

                        let { inputs } = this.state;
                        inputs.info_source = e.target.value;
                        this.setState({ inputs });
                      }}
                      className='form-control'
                      required={true}>
                      <option value='' selected hidden>
                        How did you hear of SKY?
                      </option>
                      <option value='At School'>At School</option>
                      <option value='Through a Friend'>Through a Friend</option>
                      <option value='Seen SKY Online'>Seen SKY Online</option>
                      <option value='other'>Other</option>
                    </select>
                  )}
                </div>

                <div className='col-md-12 text-left mt-3'>
                  <small>
                    <div>
                      <small className='font-weight-bold'>
                        By taking the SKY pledge, I'm happy for you to contact
                        me with updates about SKY
                      </small>
                    </div>
                    <div>
                      <small>
                        Please tick these checkboxes if you{' '}
                        <span className='text-primary'>do not</span> want us to
                        contact you via
                      </small>
                    </div>
                  </small>
                  <div className='d-flex flex-row mt-2'>
                    <label className='mr-3 d-flex flex-row align-items-center cursor'>
                      <input
                        id='hk'
                        type='checkbox'
                        className='mr-2 '
                        checked={this.state.inputs.whatsapp == 0}
                        onChange={() => {
                          let { inputs } = this.state;
                          inputs.whatsapp =
                            this.state.inputs.whatsapp == 0 ? 1 : 0;

                          this.setState({ inputs });
                        }}></input>
                      <small htmlFor='hk' className='font-weight-bold'>
                        {' '}
                        Whatsapp
                      </small>
                    </label>

                    <label className='mr-3 d-flex flex-row align-items-center cursor'>
                      <input
                        id='hk'
                        type='checkbox'
                        className='mr-2 '
                        checked={this.state.inputs.sms == 0}
                        onChange={() => {
                          let { inputs } = this.state;
                          inputs.sms = this.state.inputs.sms == 0 ? 1 : 0;

                          this.setState({ inputs });
                        }}></input>
                      <small htmlFor='hk' className='font-weight-bold'>
                        {' '}
                        SMS
                      </small>
                    </label>

                    {/* <label className='mr-3 d-flex flex-row align-items-center'>
                    <input id='hk2' type='checkbox' className='mr-2'></input>
                    <small htmlFor='hk2' className='font-weight-bold'>
                      SMS
                    </small>
                  </label> */}
                  </div>
                </div>
              </div>

              <div className='col-md-6'></div>
              <div className='text-left w-100 mt-4 col-md-6 row'>
                <button
                  className='btn btn-primary px-4 btn-round my-3'
                  disabled={this.state.pledged}>
                  Join the Sistahood
                </button>
              </div>
            </form>
          </div>

          {/* <img src={flower1} className='flower flower-left' alt='' />
          <img src={flower2} className='flower flower-right' alt='' /> */}
        </section>

        <Modal visible={this.state.showModal}>
          <div className='p-3 text-center card d-flex flex-column align-items-center'>
            <CheckCircle size={60} className='text-primary'></CheckCircle>

            <h2 className='my-3'>Thankyou</h2>
            <p className='my-3'>
              Congratulations you are now officially a SKY Girl. Remember to
              follow us on social to stay up to date with the cool things
              happening on SKY
            </p>

            <button
              className='btn btn-round btn-primary px-4'
              onClick={() => {
                this.setState({ showModal: false });
              }}>
              Ok
            </button>
          </div>
        </Modal>

        <Modal visible={this.state.showAgeLimitPopup} dismiss={false}>
          <div className='p-3 text-left  d-flex flex-column align-items-center'>
            <div>
              <div className='text-center'>
                <Info size={60} className='text-primary mb-4'></Info>
              </div>

              <p>Hi there,</p>
              <p>
                Thank you so much for taking the SKY pledge! We are super
                excited to have you on board, we know you’ll just love it here.
              </p>
              <p>
                While the SKY program is for teen girls aged 13 to 17, you can
                still join the movement as a SKY Big sis. If you're 18 years and
                above and want to support the coolest movement of teens in Kenya
                with your time and ideas, then let's make this official.
              </p>

              <p>JOIN SKY BIG SIS CREW (this is the CTA)</p>
              <p>
                Link -&gt;{' '}
                <a href='https://forms.gle/i3LYSjxj4EGex5pL7'>
                  https://forms.gle/i3LYSjxj4EGex5pL7
                </a>
              </p>
              <p>
                Cheers,
                <br />
                SKY team!
              </p>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  join = () => {
    // console.log(global.server);
    window
      .fetch(`${global.server}/pledges`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // this.setState({ showModal: true });
        window.location = '/thankyou';
      })
      .catch((err) => {});
  };
}

export default PledgeForm;
