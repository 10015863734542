import React, { Component } from 'react';

import { ChevronRight, ChevronDown } from 'react-feather';

class FAQ extends Component {
  state = { active: 0 };
  render() {
    return (
      <section className='container py-5'>
        <div className='text-center'>
          <h1 className='intro-title'>{this.props.title}</h1>

          <p className='my-4'>{this.props.content}</p>
        </div>

        <div className='faq my-5'>
          {this.props.faqs.map((d, i) => (
            <div className='faq-item my-2 ' key={i}>
              <div
                className={
                  'faq-question p-md-4 p-3 ' +
                  (i === this.state.active && 'active')
                }
                onClick={() => {
                  this.setState({ active: i });
                }}>
                <span> {d.question}</span>{' '}
                {i === this.state.active ? (
                  <ChevronDown></ChevronDown>
                ) : (
                  <ChevronRight></ChevronRight>
                )}
              </div>
              {i === this.state.active && (
                <div className='faq-description p-md-4 p-3 '>{d.answer}</div>
              )}
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default FAQ;
