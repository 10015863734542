import React, { Component } from 'react';
import SEO from '../seo';

const PageSEO = (props) => {
  return (
    <SEO {...props} image={props.banner} description={props.content}></SEO>
  );
};

export default PageSEO;
