import React, { Component } from 'react';
import Lottie from 'react-lottie';

import animationData from '../data/check.json';

class Check extends Component {
  state = {};
  render() {
    return (
      <div>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={200}
          width={200}
          isStopped={false}
          isPaused={false}
        />
      </div>
    );
  }
}

export default Check;
