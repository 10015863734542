import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Swiper from 'swiper';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

let MagazineSummarry = ({ title, content }) => {
  let data = useStaticQuery(graphql`
    query Magazine_Summarry {
      mysqlLatestMagazine {
        title
        type
        mysqlId
        description
        banner_landscape
        mysqlImages {
          childImageSharp {
            fluid(maxWidth: 1920) {
              base64
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
      }

      allMysqlArticlesSummarry {
        nodes {
          title
          mysqlId
          magazineId
          order
          date(formatString: "Do MMMM YYYY")
          banner
          mysqlImage {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `);

  let magazine = data.mysqlLatestMagazine;
  let articles = data.allMysqlArticlesSummarry.nodes;

  return (
    <section className='magazine'>
      <div className='py-5 text-center container'>
        <Link to={'/magazine/' + magazine.mysqlId}>
          <h3 className='intro-title'>{magazine.title}</h3>

          <p className='my-4 text-dark'>{magazine.description}</p>
        </Link>

        <div className='magazine-cards'>
          <Link
            to={'/magazine/' + magazine.mysqlId}
            className='article-card card-lg magazine-banner'>
            <BannerComponent
              value={magazine.mysqlImages[0]}
              type={1}></BannerComponent>
          </Link>

          {articles.map((d, i) => {
            let title = d.title;
            let date = d.date;
            let banner = d.mysqlImage;

            let card_types = {
              2: (
                <Link
                  to={'/article/' + d.mysqlId}
                  className='article-card card-md '
                  key={i}>
                  <BannerComponent value={banner} type={1}></BannerComponent>
                  <div className='article-desc mt-3'>{title}</div>
                  <div className=' article-desc mt-1 font-weight-light'>
                    {date}
                  </div>
                </Link>
              ),
              3: (
                <Link
                  to={'/article/' + d.mysqlId}
                  className='article-card card-sm '
                  key={i}>
                  <BannerComponent value={banner} type={1}></BannerComponent>
                  <div className='card-content ml-3'>
                    <div className='article-desc'>{title}</div>
                    <div className=' article-desc mt-1 font-weight-light'>
                      {date}
                    </div>
                  </div>
                </Link>
              ),
            };

            if (i < 2) {
              return card_types[2];
            } else {
              return card_types[3];
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default MagazineSummarry;
