import React from 'react';
import BannerComponent from './bannerComponent';

function Hosts(props) {
  return (
    <section className='hosts bg-white'>
      <div className='container py-5'>
        <div className='row align-items-center'>
          <div className='col-md-3 d-flex flex-row justify-content-center'>
            <h1 className='chinese-rocks text-center text-primary title-lg'>
              {props.content.title}
            </h1>

            <div className='hosts-loop'>
              <BannerComponent
                value={props.content.banner}
                type={1}></BannerComponent>
            </div>
          </div>

          <div
            className='col-md-9 row position-relative text-md-left text-center'
            style={{ zIndex: 10 }}>
            {props.content.hosts &&
              props.content.hosts.map((d, i) => (
                <div className='col-md-6 mt-4 host' key={i}>
                  <div className='row align-items-center'>
                    <div className='col-md-5 headshot'>
                      <BannerComponent
                        value={d.banner}
                        type={1}></BannerComponent>
                    </div>
                    <div className='col-md-7'>
                      <h6 className='font-weight-bold'>{d.name}</h6>
                      <small>
                        <p dangerouslySetInnerHTML={{ __html: d.content }}></p>
                      </small>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hosts;
