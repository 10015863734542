import React, { Component } from 'react';
import flower1 from '../../img/left-flower.png';
import flower2 from '../../img/right-flower.png';
import Modal from '../modal';
import { Link } from 'gatsby';
import { CheckCircle } from 'react-feather';

class ContactFormFR extends Component {
  state = {
    inputs: {
      name: null,
      email: null,
      message: null,
      user_type: 1,
    },

    disabled: false,
  };
  render() {
    return (
      <>
        <section className=' magazine position-relative'>
          <div className='py-5 text-center container'>
            <h1 className='intro-title mb-4'>{this.props.title}</h1>
            <p>{this.props.content}</p>

            <form
              action=''
              onSubmit={(e) => {
                e.preventDefault();
                this.submit();
                this.setState({ disabled: true });
              }}
              className='px-3 pledge-form row'>
              <div className='my-3 col-md-6'>
                <input
                  placeholder='Nom'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.name = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='text'
                  className='form-control unbound-input'
                />
              </div>
              <div className='my-3 col-md-6'>
                <input
                  placeholder='Email'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.email = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='email'
                  className='form-control unbound-input'
                />
              </div>

              <div className='col-md-12 my-3'>
                <textarea
                  rows='10'
                  placeholder='Message'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.message = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='tel'
                  className='form-control w-100 unbound-input'></textarea>
              </div>

              <div className='col-md-12'>
                <div className='d-flex flex-row mt-2'>
                  <small htmlFor='hk' className='font-weight-bold mr-3'>
                    {' '}
                    Je suis
                  </small>

                  <label className='mr-3 d-flex flex-row align-items-center cursor'>
                    <input
                      id='hk'
                      type='radio'
                      name='user_type'
                      required={true}
                      className='mr-2 '
                      checked={this.state.inputs.user_type == 1}
                      onChange={() => {
                        let { inputs } = this.state;
                        inputs.user_type = 1;

                        this.setState({ inputs });
                      }}></input>
                    <small htmlFor='hk' className='font-weight-bold'>
                      {' '}
                      Une SKY Girl
                    </small>
                  </label>

                  <label className='mr-3 d-flex flex-row align-items-center cursor'>
                    <input
                      id='hk'
                      type='radio'
                      name='user_type'
                      required={true}
                      className='mr-2 '
                      checked={this.state.inputs.user_type == 2}
                      onChange={() => {
                        let { inputs } = this.state;
                        inputs.user_type = 2;

                        this.setState({ inputs });
                      }}></input>
                    <small htmlFor='hk' className='font-weight-bold'>
                      Un SKY Parent
                    </small>
                  </label>

                  {/* <label className='mr-3 d-flex flex-row align-items-center'>
              <input id='hk2' type='checkbox' className='mr-2'></input>
              <small htmlFor='hk2' className='font-weight-bold'>
                SMS
              </small>
            </label> */}
                </div>
              </div>

              <div className='text-center w-100 mt-4'>
                <button
                  className='btn btn-primary px-5 btn-round my-3'
                  disabled={this.state.disabled}>
                  Envoyer
                </button>
              </div>
            </form>
          </div>

          <img src={flower1} className='flower flower-left' alt='' />
          <img src={flower2} className='flower flower-right' alt='' />
        </section>

        <Modal visible={this.state.showModal}>
          <div className='p-3 text-center card d-flex flex-column align-items-center'>
            <CheckCircle size={60} className='text-primary'></CheckCircle>

            <h2 className='my-3'>Thankyou!</h2>
            <p className='my-3'>
              We will get back to you soon. Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Beatae alias dolorem, recusandae
              temporibus.
            </p>

            <Link to='/' className='btn btn-round btn-primary px-4'>
              Ok
            </Link>
          </div>
        </Modal>
      </>
    );
  }

  submit = () => {
    if (this.state.disabled) return;
    window
      .fetch(`${global.server}/contacts`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        window.location = '/thankyou';
        // this.setState({ showModal: true });
      });
  };
}

export default ContactFormFR;
