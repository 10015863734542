import React, { Component } from 'react';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class TextSection extends Component {
  state = {};
  render() {
    // console.log(this.props);
    return (
      <div>
        <section
          className={`${this.props.text_color} ${this.props.background_color} ${this.props.text_align}`}>
          <div className='my-4 container'>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.content,
              }}></div>
          </div>
        </section>
      </div>
    );
  }
}

export default TextSection;
