import React, { Component } from 'react';
import sep1 from '../../img/separator 1.svg';
import sep2 from '../../img/separator 2.svg';
import sep3 from '../../img/separator 3.svg';

class Divider extends Component {
  state = {};
  render() {
    return (
      <section
        className={`container divider-section my-4 bg-${this.props.text_color} ${this.props.className}`}>
        <div className='text-center'>
          {this.props.divider == 1 && <img src={sep1} alt='' />}
          {this.props.divider == 2 && <img src={sep2} alt='' />}
          {this.props.divider == 3 && <img src={sep3} alt='' />}
        </div>
      </section>
    );
  }
}

export default Divider;
