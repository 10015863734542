import React, { Component } from 'react';
import BannerComponent from './bannerComponent';
import { Link } from 'gatsby';

class SkyAnthem extends Component {
  state = {};
  render() {
    return (
      <section className='container anthem'>
        <div className='py-5 text-center'>
          <h1 className='intro-title'>{this.props.title}</h1>

          <p className='my-4'>{this.props.content}</p>

          <div className='magazine-cards'>
            <Link to='/article' className='article-card card-lg'>
              <div className='video-section '>
                <BannerComponent
                  value={this.props.banner}
                  type={this.props.banner_type}></BannerComponent>
              </div>
            </Link>

            {(this.props.articles || []).map((d, i) => (
              <Link to='/article' className='article-card card-md' key={i}>
                <BannerComponent
                  value={this.props.banner}
                  type={this.props.banner_type}
                  // width={200}
                  // height={200}
                ></BannerComponent>
                <div className='article-desc mt-3'>{d.title}</div>
                <div className='text-light article-desc mt-1'>
                  {d.description}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default SkyAnthem;
