import React, { Component } from 'react';
import BannerComponent from './bannerComponent';

class DramaDescrption extends React.Component {
  render() {
    return (
      <section className='bg-dark text-white'>
        <div className='container'>
          <div className='dramatic-title pb-4'>About</div>
          <div className='row'>
            <div className='col-md-5'>
              <BannerComponent
                value={'https://www.youtube.com/embed/BxajsQMwfTQ'}
                className={'drama-bg'}
                type={2}></BannerComponent>

              <div className='py-4'></div>

              <BannerComponent
                value={'https://www.youtube.com/embed/jiH8I5muFV0'}
                className={'drama-bg'}
                type={2}></BannerComponent>
            </div>

            <div className='col-md-7'>
              <table className='cast-table'>
                <tr>
                  <td>
                    {' '}
                    <small className='text-light'>Summary:</small>{' '}
                  </td>

                  <td>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </td>
                </tr>

                <tr>
                  <td>
                    {' '}
                    <small className='text-light'>Creator:</small>{' '}
                  </td>

                  <td>Vince Gilligan</td>
                </tr>

                <tr>
                  <td>
                    {' '}
                    <small className='text-light'>Summary:</small>{' '}
                  </td>

                  <td>
                    Bryan Cranston , Anna Gunn , Aaron Paul , Dean Norris ,
                    Betsy Brandt , RJ Mitte, Bob Odenkirk , Giancarlo Esposito ,
                    Jonathan Banks , Laura Fraser , Jesse Plemons
                  </td>
                </tr>
              </table>

              <div className='text-center'>
                <div className='cast-item text-center p-3'>
                  <div className='cast-img'></div>

                  <div className='text-white mt-2'>
                    <div className='text-primary text-uppercase'>Lydia</div>
                    <small>Mercy Mumbi</small>
                  </div>
                </div>

                <div className='cast-item text-center p-3'>
                  <div className='cast-img'></div>

                  <div className='text-white mt-2'>
                    <div className='text-primary text-uppercase'>Talia</div>
                    <small>Mercy Mumbi</small>
                  </div>
                </div>

                <div className='cast-item text-center p-3'>
                  <div className='cast-img'></div>

                  <div className='text-white mt-2'>
                    <div className='text-primary text-uppercase'>neema</div>
                    <small>Mercy Mumbi</small>
                  </div>
                </div>

                <div className='cast-item text-center p-3'>
                  <div className='cast-img'></div>

                  <div className='text-white mt-2'>
                    <div className='text-primary text-uppercase'>Bobo</div>
                    <small>Mercy Mumbi</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DramaDescrption;
