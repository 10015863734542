import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

let MagazinesList = ({ title, content }) => {
  let data = useStaticQuery(graphql`
    query previousMagazines {
      allMysqlMagazines {
        nodes {
          banner_portrait
          mysqlId
          title
          mysqlImages {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `);

  let magazines = data.allMysqlMagazines.nodes;

  return (
    <section className='magazines-list container my-5 py-5'>
      <div class='row'>
        {magazines
          .sort((a, b) => b.mysqlId - a.mysqlId)
          .map((d, i) => (
            <div class='col-md-4 ' key={i}>
              <div className='magazine-cover'>
                <Link to={'/magazine/' + d.mysqlId}>
                  <BannerComponent
                    value={d.mysqlImages[1]}
                    type={1}></BannerComponent>
                </Link>
              </div>
              <div className='p-3 text-center'>{d.title}</div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default MagazinesList;
