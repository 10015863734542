import React, { Component } from 'react';

class Title extends Component {
  state = {};
  render() {
    return (
      <section
        className={`container my-3 bg-${this.props.text_color} ${this.props.className}`}>
        <h1 className='intro-title text-center'>{this.props.title}</h1>
      </section>
    );
  }
}

export default Title;
