import React, { Component } from 'react';
import componentList from './page/componentList';

class Page extends Component {
  state = {
    componentList,
  };
  cardList = false;
  cards = [];
  render() {
    return (
      <>
        {this.props.content.map((d, i) => {
          // console.log(d.type);
          let Item = this.state.componentList[d.type].component;

          if (d.type === 'card') {
            this.cards.push(<Item {...d} key={i}></Item>);

            if (
              !this.props.content[i + 1] ||
              this.props.content[i + 1].type !== 'card'
            ) {
              return (
                <>
                  <div className='container'>
                    <div className='row'> {this.cards}</div>
                  </div>
                </>
              );
            }
            return <></>;
          }

          return <Item {...d} key={i}></Item>;
        })}
      </>
    );
  }
}

export default Page;
