import moment from 'moment';
import React, { Component } from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import QRCode from 'react-qr-code';

class EventTicket extends Component {
  state = {
    response: {},
    loading: true,
    showParent: false,
    errors: {},
    inputs: {
      phone: '',
    },
    ticket: {},
  };
  render() {
    return (
      <div className='ticket-parent'>
        <div className='d-flex flex-row justify-content-center '>
          {this.state.response.id && (
            <div className='ticket my-5'>
              <div className='ticket-title text-center p-4 shadow'></div>

              <div className='ticket-details p-4 shadow'>
                <div className='ticket-name'>{this.state.response.name}</div>

                <div className='ticket-divider my-3'></div>

                <div className='ticket-date my-4'>
                  <div className='mb-2 fnt'>
                    {moment(this.state.response.slot.date).format(
                      'dddd Do MMMM'
                    )}
                  </div>

                  <div>
                    <small className=''> Time : </small>
                    <div className='fnt'>
                      {moment(this.state.response.slot.start).format('hh:mm a')}{' '}
                      - {moment(this.state.response.slot.end).format('hh:mm a')}
                    </div>
                  </div>
                </div>

                <div className='ticket-divider my-3'></div>

                <div>
                  <small className=''> Parental consent : </small>
                  <div className='fnt'>
                    {this.state.response.parent_consent === 1 ? (
                      <span className='text-success'>CONSENTED</span>
                    ) : (
                      <span className='text-danger'>
                        {this.state.response.parent_consent === 0
                          ? 'PENDING'
                          : 'REJECTED'}
                      </span>
                    )}
                  </div>
                </div>

                <div className='ticket-divider my-3'></div>

                {/* {this.state.response.status === 1 && (
                  <div>
                    <p>Will you still be attending?</p>
                    <div>
                      <button
                        className='btn btn-warning'
                        onClick={() => {
                          this.updateTicket(2);
                        }}>
                        YES
                      </button>
                      <button
                        className='btn btn-outline-warning mx-3'
                        onClick={() => {
                          this.updateTicket(3);
                        }}>
                        MAYBE
                      </button>
                      <button
                        className='btn btn-outline-warning'
                        onClick={() => {
                          this.updateTicket(-1);
                        }}>
                        NO
                      </button>
                    </div>
                  </div>
                )} */}

                <div className='mt-5 mb-3 d-flex flex-row justify-content-center'>
                  <QRCode value={this.state.response.code} size={200} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchTicket();
  }

  fetchTicket = () => {
    window.Alert.show({
      loader: true,
      icon: false,
      loader: true,
      title: 'Please wait...',
      message: 'Verifiying your ticket ...',
      buttons: false,
    });

    var code = new URLSearchParams(window.location.search).get('c');

    window
      .fetch(`${global.server}/event-verify/` + code, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.id) {
          window.Alert.hide();
          this.setState({ response });
        } else {
          this.phoneNumberPrompt();
          // this.setState({ bookingError: response.message, loading: false });
        }

        if (response.code) {
          this.setState({ bookingError: response.message, loading: false });
          // this.phoneNumberPrompt();

          return;
        }

        this.setState({ loading: false });
      })
      .catch((err) => {
        this.phoneNumberPrompt();
        console.error(err);
      });
  };

  updateTicket = (status) => {
    window
      .fetch(`${global.server}/event-verify/` + this.state.response.id, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      })
      .then((response) => response.json())
      .then((response) => {
        // this.fetchTicket();

        this.setState({ loading: false });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  phoneNumberPrompt = () => {
    // ask user to enter phone number
    window.Alert.show({
      loader: false,
      title: 'Enter your phone number',
      message: (
        <>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              placeholder='Phone Number'
              // value={this.state.inputs.phone}
              onChange={(e) => {
                this.setState({
                  inputs: {
                    ...this.state.inputs,
                    phone: e.target.value,
                  },
                });
              }}
            />
            <small className='text-danger' id='phone-error'>
              {this.state.errors.phone}
            </small>
          </div>
        </>
      ),
      buttons: true,
      buttonText: 'Get Ticket',
      onSubmit: () => {
        this.verify();
      },
    });
  };

  formatNumber = (num) => {
    num = num.replace(/(\+|\s)/g, '');
    num = parseInt(num) + '';
    if (num.substring(0, 3) !== '254') {
      num = '254' + num;
    }
    return num;
  };

  verify = () => {
    let { inputs } = this.state;

    inputs.phone = this.formatNumber(inputs.phone);

    if (inputs.phone.length !== 12) {
      this.setState({
        errors: {
          phone: '*Phone Number is invalid*',
        },
      });

      this.phoneNumberPrompt();

      return;
    }

    window.Alert.show({
      icon: false,
      loader: true,
      title: 'Please wait...',
      message: 'Verifiying your ticket ...',
      buttons: false,
    });

    window
      .fetch(`${global.server}/event-verify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.code);

        if (response.code) {
          this.setState({ bookingError: response.message, loading: false });

          window.Alert.show({
            loader: false,
            icon: <AlertCircle size={60}></AlertCircle>,
            title: 'Booking Not Found',
            message: (
              <>
                <p>
                  Your Booking was not found
                  <br />
                  Please check your phone number and try again
                </p>
              </>
            ),
            buttons: true,
            buttonText: 'Try Again',
            onSubmit: () => {
              this.phoneNumberPrompt();
            },
          });

          return;
        }

        this.setState({ loading: false, ticket: { id: response.id } });

        setTimeout(() => {
          this.updateTicket(2);
        }, 0);

        window.Alert.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Ticket Verified',
          message: (
            <>
              <p>
                Your Booking has been verified
                <br />A confirmation SMS with a link to your virtual ticket has
                been sent to your phone.
              </p>
              <p>The ticket will contain all your booking information</p>
            </>
          ),
          buttons: false,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateTicket = (status) => {
    window
      .fetch(`${global.server}/event-verify/` + this.state.ticket.id, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.code) {
          this.setState({ bookingError: response.message, loading: false });

          return;
        }

        let messages = {
          2: (
            <>
              <p>
                Your Booking has been verified
                <br />A confirmation SMS with a link to your virtual ticket has
                been sent to your phone.
              </p>
              <p>The ticket will contain all your booking information</p>
            </>
          ),
          3: (
            <>
              <p>
                Your Booking has been verified
                <br />A confirmation SMS with a link to your virtual ticket has
                been sent to your phone.
              </p>
              <p>The ticket will contain all your booking information</p>
            </>
          ),
          '-1': (
            <>
              <p>We have recieved your response successfully.</p>
            </>
          ),
        };

        this.setState({ success: true, bookingMessage: messages[status] });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default EventTicket;
