import React, { Component } from 'react';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class CardList extends Component {
  state = {};

  render() {
    return (
      <section className='container'>
        <div className='row'>
          {this.props.cards.map((d, i) => (
            <div className={'col-md-3 d-inline-block my-3 my-md-4'} key={i}>
              {this.props.banner && (
                <BannerComponent
                  value={this.props.banner}
                  type={1}></BannerComponent>
              )}

              <div className='mt-3'>
                <div
                  className={this.props.content_color}
                  dangerouslySetInnerHTML={{
                    __html: this.props.content,
                  }}></div>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default CardList;
