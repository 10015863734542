import React, { Component } from 'react';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class ImageSection extends Component {
  state = {};
  render() {
    // console.log(this.props);
    return (
      <section
        className={`image-section text-center my-5 ${this.props.text_color} ${this.props.background_color} ${this.props.text_align}`}>
        <div className='container'>
          {this.props.banner && (
            <BannerComponent
              value={this.props.banner}
              type={this.props.banner_type}></BannerComponent>
          )}
        </div>
      </section>
    );
  }
}

export default ImageSection;
