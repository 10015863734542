import React, { Component } from 'react';
import facebook from '../../img/icons/facebook.svg';
import instagram from '../../img/icons/instagram.svg';
import youtube from '../../img/icons/youtube.svg';
import tiktok from '../../img/icons/tiktok.png';

class SocialLinks extends Component {
  state = {};
  render() {
    return (
      <section
        className={`join-section ${this.props.background_color} ${this.props.class_name}`}>
        <div className='join-pattern'></div>
        {/* <img src={pattern} className="" alt="" /> */}
        <div className='py-5 text-center container position-relative'>
          <h2 className='join-intro-text'>{this.props.title}</h2>
          <div className='mt-4'>
            {this.props.facebook && this.props.facebook !== '' && (
              <a target='_blank' href={this.props.facebook}>
                <img src={facebook} className='social-icon mx-2' alt='' />
              </a>
            )}
            {this.props.instagram && this.props.instagram !== '' && (
              <a target='_blank' href={this.props.instagram}>
                <img src={instagram} className='social-icon mx-2' alt='' />
              </a>
            )}
            {this.props.youtube && this.props.youtube !== '' && (
              <a target='_blank' href={this.props.youtube}>
                <img src={youtube} className='social-icon mx-2' alt='' />
              </a>
            )}
            {this.props.titok && this.props.titok !== '' && (
              <a target='_blank' href={this.props.titok}>
                <img src={tiktok} className='social-icon mx-2' alt='' />
              </a>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default SocialLinks;
