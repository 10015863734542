import React, { Component } from 'react';

import youtubeLogo from '../../img/youtube_logo.png';
import hand from '../../img/hand.png';
import bg1 from '../../img/bg1.png';
import bg2 from '../../img/bg2.png';
import { Play } from 'react-feather';
import { Link } from 'gatsby';

class VideoPlaylist extends Component {
  state = {};
  render() {
    return (
      <section
        className={`${this.props.text_color}  ${this.props.background_color} ${this.props.className} position-relative youtube-video-playlist`}>
        {/* <img src={bg1} className='sista-bg1' alt='' />
        <img src={bg2} className='sista-bg2' alt='' /> */}

        <div className='pb-5'>
          <div className=''>
            {this.props.highlights != '0' && (
              <div className='intro-section'>
                <div className='pt-4 container'>
                  <div className='row mb-4 pt-5 align-items-center'>
                    <div className='col-md-5 order-md-3 pb-md-0 pb-4'>
                      <h1 className='chinese-rocks text-primary paper-font pr-md-4 py-4'>
                        {this.props.title}
                      </h1>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.videos[0].content,
                        }}
                        className='intro-content'></div>

                      {/* <img src={hand} className='sista-chill' alt='' /> */}
                    </div>

                    <div className='col-md-1 order-md-1'></div>
                    <div className='col-md-5 order-md-2'>
                      <div className='iframe-parent'>
                        <iframe
                          title='254'
                          // width={width}
                          // height={height}
                          src={this.props.videos[0].embed_url}
                          frameborder='0'
                          className='iframe-preview'
                          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                          allowfullscreen></iframe>
                      </div>
                    </div>

                    {/* <div className='col-md-1'></div> */}
                  </div>
                </div>
              </div>
            )}

            <div className='container'>
              <div className='d-flex flex-row play-list-text align-items-center justify-content-between'>
                {this.props.season && (
                  <h2 className='mr-3 chinese-rocks mt-5'>
                    {this.props.season}
                  </h2>
                )}

                {this.props.link && (
                  <Link href={this.props.link} className='play-all-link mt-5'>
                    <Play size={20}></Play> PLAY ALL
                  </Link>
                )}
              </div>

              <div className='row align-items-start'>
                {this.props.videos.map((d, i) => {
                  if (i)
                    return (
                      <div
                        className={
                          'col-md-3 col-12 my-4 text-center video-list-item ' +
                          (d.comingsoon ? 'coming-soon' : '')
                        }
                        key={i}>
                        <a
                          href={d.comingsoon ? '#' : d.link}
                          target={d.comingsoon ? '' : '_blank'}>
                          <div className='video-container'>
                            <img
                              src={d.thumbnail_url}
                              className='video-banner'
                              alt=''
                            />
                            <img
                              src={youtubeLogo}
                              className='video-icon'
                              alt=''
                            />
                          </div>

                          <div className='mt-3 video-title'>{d.title}</div>
                        </a>
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  componentDidMount() {
    // console.log(this.props);
  }
}

export default VideoPlaylist;
