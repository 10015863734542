import { Link } from 'gatsby';
import React, { Component } from 'react';
import BannerComponent from './bannerComponent';

class Banner extends Component {
  state = { ...this.props };
  render() {
    return (
      <div className={'banner bg-secondary ' + this.props.class_name}>
        {
          <a href={this.props.link} target='_blank'>
            <BannerComponent
              value={this.props.banner}
              type={this.props.banner_type}></BannerComponent>
          </a>
        }
      </div>
    );
  }
}

export default Banner;
