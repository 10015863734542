import { navigate } from 'gatsby';
import React, { Component } from 'react';
import { AlertCircle, CheckCircle, HelpCircle } from 'react-feather';

class ConsentForm extends Component {
  state = { inputs: { status: 1 }, consent1: false, consent2: false };
  render() {
    return (
      <div className='container'>
        <div className='row mb-5'>
          <form
            className='col-md-6'
            onSubmit={(e) => {
              e.preventDefault();
              if (!(this.state.consent1 && this.state.consent2)) return;

              this.submit();
            }}>
            <div className='card p-4 consent-card mb-4'>
              <div>
                <label>Name</label>
                <input
                  type='text'
                  className='form-control'
                  required={true}
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.name = e.target.value;
                    this.setState({ inputs });
                  }}
                />
              </div>

              <div className='mt-3'>
                <label>ID Number</label>
                <input
                  type='tel'
                  className='form-control'
                  required={true}
                  value={this.state.inputs.parent_id_number}
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.parent_id_number = e.target.value;
                    this.setState({ inputs });
                  }}
                />
              </div>

              <div className='mt-3'>
                <label>Phone</label>
                <input
                  type='tel'
                  className='form-control'
                  required={true}
                  value={this.state.inputs.phone}
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.phone = e.target.value;
                    this.setState({ inputs });
                  }}
                />
              </div>

              <div className='mt-3'>
                <label>Daughter's name</label>
                <input
                  type='text'
                  className='form-control'
                  required={true}
                  value={this.state.inputs.child_name}
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.child_name = e.target.value;
                    this.setState({ inputs });
                  }}
                />
              </div>
              <div className='mt-3'>
                <label>Daughter's phone Number</label>
                <input
                  type='tel'
                  className='form-control'
                  required={true}
                  value={this.state.inputs.child_phone}
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.child_phone = e.target.value;
                    this.setState({ inputs });
                  }}
                />
              </div>
            </div>

            <div className='my-3'>
              <div
                class='form-check'
                onClick={() =>
                  this.setState({ consent1: !this.state.consent1 })
                }>
                <input
                  class='form-check-input'
                  type='checkbox'
                  name='check1'
                  required={true}
                  checked={this.state.consent1}
                />
                <label class='form-check-label' for='defaultCheck1'>
                  I have fully discussed the content of this form with the
                  person(s) mentioned above.
                </label>
              </div>
            </div>

            <div className='my-3'>
              <div
                class='form-check'
                onClick={() =>
                  this.setState({ consent2: !this.state.consent2 })
                }>
                <input
                  class='form-check-input'
                  type='checkbox'
                  name='check2'
                  required={true}
                  checked={this.state.consent2}
                />
                <label class='form-check-label' for='defaultCheck1'>
                  I understand that I do not have to participate.
                </label>
              </div>
            </div>

            <div className='my-3 mt-4 d-flex flex-row justify-content-between'>
              <button
                className='btn btn-primary btn-round px-4'
                // disabled={!this.state.consent1 || !this.state.consent2}
              >
                Agree
              </button>

              <button
                type='button'
                onClick={this.disagreeConfirm}
                className='btn btn-outline-primary btn-round px-4'>
                Do not Agree
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchTicket();
  }

  formatNumber = (num) => {
    if (!num) return '';
    num = num.replace(/(\+|\s)/g, '');
    num = parseInt(num) + '';
    if (num.substring(0, 3) !== '254') {
      num = '254' + num;
    }
    return num;
  };

  submit = () => {
    window.Alert.show({
      icon: false,
      loader: true,
      title: 'Please wait...',
      buttons: false,
    });

    window
      .fetch(`${global.server}/parents-consent`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...this.state.inputs,
          child_phone: this.formatNumber(this.state.inputs.child_phone),
          phone: this.formatNumber(this.state.inputs.phone),
        }),
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.code === 500) {
          this.setState({ bookingError: response.message, loading: false });

          return;
        }

        navigate('/parents');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  disagreeConfirm = () => {
    window.Alert.show({
      icon: <HelpCircle size={60} />,
      title: 'Are you sure you want to cancel this booking?',
      close: true,
      onSubmit: () => {
        this.doNotAgree();
      },
      buttonText: 'Cancel Booking',
    });
  };

  doNotAgree = () => {
    let error = false;
    // check if phone number is valid
    if (!this.state.inputs.child_phone)
      error = "Please enter your child's phone number";

    if (!this.state.inputs.child_name) error = "Please enter your child's name";

    // if (!this.state.inputs.phone) error = 'Please enter your phone number';

    // if (!this.state.inputs.name) error = 'Please enter your name';

    // if (!this.state.inputs.parent_id_number)
    //   error = 'Please enter your ID number';

    if (error) {
      window.Alert.show({
        icon: <AlertCircle size={60} />,
        title: error,
        close: true,
        onSubmit: false,
        buttonText: 'ok',
      });

      return;
    }

    window.Alert.show({
      icon: false,
      loader: true,
      title: 'Please wait...',
      buttons: false,
    });

    window
      .fetch(`${global.server}/parents-consent`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...this.state.inputs,
          status: -1,
          child_phone: this.formatNumber(this.state.inputs.child_phone),
          phone: this.formatNumber(this.state.inputs.phone),
        }),
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        if (response.code === 500) {
          this.setState({ bookingError: response.message, loading: false });

          window.Alert.show({
            loader: false,
            icon: <AlertCircle size={60} />,
            title: 'Submit Error',
            message: response.message,
            close: false,
            buttons: true,
            onSubmit: () => {
              window.Alert.hide();
            },
            buttonText: 'Close',
          });

          return;
        }

        window.Alert.show({
          loader: false,
          icon: <CheckCircle size={60} />,
          title: 'Booking cancelled',
          close: false,
          buttons: true,
          onSubmit: () => {
            navigate('/parents');
          },
          buttonText: 'Continue',
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchTicket = () => {
    var code = new URLSearchParams(window.location.search).get('c');

    window
      .fetch(`${global.server}/event-verify/` + code, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((response) => {
        let { inputs } = this.state;
        if (response.id) {
          console.log(response);
          inputs.eventDatumId = response.id;
          inputs.child_name = response.name;
          inputs.child_phone = response.phone;
          inputs.phone = response.phone_parent;
          this.setState({ inputs });

          // show approved check if consent is already approved
          if (response.parent_consent === 2) {
            window.Alert.show({
              icon: <CheckCircle size={60} />,
              title: 'Consent form approved',
              message: 'This consent form has been approved already',
              close: false,
              buttons: true,
              onSubmit: () => {
                navigate('/parents');
              },
            });
          }

          if (response.parent_consent === -1) {
            window.Alert.show({
              icon: <CheckCircle size={60} />,
              title: 'consent Rejected',
              message: 'This consent form has been rejected already',
              close: true,
              buttons: true,
              buttonText: 'Continue',
              onSubmit: () => {
                navigate('/parents');
              },
            });
          }
        }

        this.setState({ loading: false });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default ConsentForm;
