import React, { Component } from 'react';
import BannerComponent from './bannerComponent';

class DramaBanner extends Component {
  render() {
    return (
      <section className=' bg-dark drama-banner'>
        <div className='drama-bg'>
          {this.props.banner && (
            <BannerComponent
              value={this.props.banner}
              // className={'drama-bg'}
              type={1}></BannerComponent>
          )}
        </div>

        <div className='container py-5'>
          <div className='row align-items-center py-md-4 text-white'>
            <div className='col-md-5 order-2 order-md-1'>
              <div className='img-parent '>
                {this.props.image && (
                  <BannerComponent
                    value={this.props.image}
                    type={2}></BannerComponent>
                )}
              </div>
            </div>

            <div className='col-md-7 text-wite order-1 order-md-2'>
              <h3 className='font-weight-bold'> {this.props.title} </h3>

              {/* <p>43 Minutes</p> */}

              <div className='badge badge-primary btn-custom-light px-2 my-3'>
                Drama
              </div>

              <div
                className='my-3'
                dangerouslySetInnerHTML={{
                  __html: this.props.content,
                }}></div>
              <hr />

              {/* <div className="my-3">
                <button className="btn btn-primary px-3">
                  {" "}
                  Play <Play size={18}></Play>{" "}
                </button>

                <button className="btn btn-primary btn-custom-light px-3 ml-4">
                  Watch Trailer
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }

  componentDidMount() {
    console.log(this.props.banner);
  }
}

export default DramaBanner;
