import React, { Component } from 'react';

import flower1 from '../../img/left-flower.png';
import flower2 from '../../img/right-flower.png';
import Modal from '../modal';
import { CheckCircle } from 'react-feather';

class PledgeFormFR extends Component {
  state = {
    inputs: {
      email: '',
      phone: '',
      location: '',
      whatsapp: 1,
      sms: 1,
    },
    showOtherLocation: false,
    pledged: false,
  };
  render() {
    return (
      <>
        <section className=' magazine position-relative'>
          <div className='py-5 text-center container'>
            <h1 className='intro-title mb-4'>Engage toi aujourd'hui</h1>
            <p>
              En vous engageant, vous rejoignez la communauté SKY, une
              communauté de pairs pour vous aider à suivre vos passions.
              Renseignez vos informations & cliquez sur le bouton rose ci
              dessous.
            </p>

            <form
              action=''
              onSubmit={(e) => {
                e.preventDefault();
                // console.log(this.state.inputs);

                this.setState({ pledged: true });
                this.join();
              }}
              className='px-3 pledge-form row'>
              <div className='my-3 col-md-4'>
                <input
                  placeholder='Nom'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.first_name = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='text'
                  className='form-control'
                />
              </div>
              <div className='my-3 col-md-4'>
                <input
                  placeholder='Prénom'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.last_name = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='text'
                  className='form-control'
                />
              </div>
              <div className='my-3 col-md-4'>
                <input
                  placeholder='Date'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.dob = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='date'
                  className='form-control'
                />
              </div>
              <div className='my-3 col-md-4'>
                <input
                  placeholder='Téléphone'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.phone = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='tel'
                  className='form-control'
                />
              </div>
              <div className='my-3 col-md-4'>
                <input
                  placeholder='Email'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.email = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='email'
                  className='form-control'
                />
              </div>
              {/* <div className='my-3 col-md-4'>
                <input
                  placeholder='Mobile'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.phone = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  type='tel'
                  className='form-control'
                />
              </div> */}
              <div className='my-3 col-md-4'>
                {this.state.showOtherLocation ? (
                  <input
                    placeholder='Où habitez-vous ?'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.location = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    type='address'
                    className='form-control'
                  />
                ) : (
                  <select
                    name=''
                    id=''
                    onChange={(e) => {
                      // console.log(e.target.value);
                      if (e.target.value === 'other') {
                        this.setState({ showOtherLocation: true });
                        setTimeout(() => {}, 0);
                        return false;
                      }

                      let { inputs } = this.state;
                      inputs.location = e.target.value;
                      this.setState({ inputs });
                    }}
                    className='form-control'
                    required={true}>
                    <option value='' selected hidden>
                      Où habitez-vous ?
                    </option>

                    <option value='Abidjan'>Abidjan</option>
                    <option value='Yamoussoukro'>Yamoussoukro</option>
                    <option value='Lacs'>Lacs</option>
                    <option value='Comoé'>Comoé</option>
                    <option value='Denguélé'>Denguélé</option>
                    <option value='Gôh-Djiboua'>Gôh-Djiboua</option>
                    <option value='Lagunes'>Lagunes</option>
                    <option value='Montagnes'>Montagnes</option>
                    <option value='Sassandra-Marahoué'>
                      Sassandra-Marahoué
                    </option>
                    <option value='Savanes'>Savanes</option>
                    <option value='Bas-Sassandra'>Bas-Sassandra</option>
                    <option value='Vallée du Bandama'>Vallée du Bandama</option>
                    <option value='Woroba'>Woroba</option>
                    <option value='Zanzan'>Zanzan</option>
                    <option value='other'>Autre</option>
                  </select>
                )}
              </div>
              <div className='my-3 col-md-4'>
                {this.state.showOtherInfo ? (
                  <input
                    placeholder='How did you hear of SKY?'
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.info_source = e.target.value;
                      this.setState({ inputs });
                    }}
                    required={true}
                    type='address'
                    className='form-control'
                  />
                ) : (
                  <select
                    name=''
                    id=''
                    onChange={(e) => {
                      // console.log(e.target.value);
                      if (e.target.value === 'other') {
                        this.setState({ showOtherInfo: true });
                        setTimeout(() => {}, 0);
                        return false;
                      }

                      let { inputs } = this.state;
                      inputs.info_source = e.target.value;
                      this.setState({ inputs });
                    }}
                    className='form-control'
                    required={true}>
                    <option value='' selected hidden>
                      Comment avez-vous entendu parler de SKY ?
                    </option>

                    <option value="À l'école">À l'école</option>
                    <option value='Amis'>Amis</option>
                    <option value='Vu en ligne'>Vu en ligne</option>
                    <option value='Autre'>Autre</option>
                  </select>
                )}
              </div>

              <div className='col-md-5'></div>
              <div className='col-md-6 text-left'>
                <label className='mb-2'>
                  <small>
                    <small className='font-weight-bold'>
                      Quel est ta gamme ?
                    </small>
                  </small>
                </label>
                <textarea
                  placeholder='1.'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.thing1 = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  className='form-control'
                />
                <textarea
                  placeholder='2.'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.thing2 = e.target.value;
                    this.setState({ inputs });
                  }}
                  // required={true}

                  className='form-control'
                />
              </div>
              <div className='col-md-6 text-left'>
                <label className='mb-2'>
                  <small>
                    <small className='font-weight-bold'>
                      Quel n'est pas ta gamme ?
                    </small>
                  </small>
                </label>
                <textarea
                  placeholder='1.'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.not_thing1 = e.target.value;
                    this.setState({ inputs });
                  }}
                  required={true}
                  className='form-control'
                />
                <textarea
                  placeholder='2.'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.not_thing2 = e.target.value;
                    this.setState({ inputs });
                  }}
                  // required={true}

                  className='form-control'
                />
              </div>
              <div className='col-md-8 text-left mt-3'>
                <div>
                  <small className='font-weight-bold'>
                    En m'enageant avec SKY, je vous autorise à me contacter pour
                    être informé(e) des nouvelles de SKY
                  </small>
                </div>
                <div>
                  <small>
                    Merci de cocher les moyens par lesquels vous ne{' '}
                    <span className='text-primary'>voulez pas</span> être
                    contactés
                  </small>
                </div>
                <div className='d-flex flex-row mt-2'>
                  <label className='mr-3 d-flex flex-row align-items-center cursor'>
                    <input
                      id='hk'
                      type='checkbox'
                      className='mr-2 '
                      checked={this.state.inputs.whatsapp == 0}
                      onChange={() => {
                        let { inputs } = this.state;
                        inputs.whatsapp =
                          this.state.inputs.whatsapp == 0 ? 1 : 0;

                        this.setState({ inputs });
                      }}></input>
                    <small htmlFor='hk' className='font-weight-bold'>
                      {' '}
                      Whatsapp
                    </small>
                  </label>

                  <label className='mr-3 d-flex flex-row align-items-center cursor'>
                    <input
                      id='hk'
                      type='checkbox'
                      className='mr-2 '
                      checked={this.state.inputs.sms == 0}
                      onChange={() => {
                        let { inputs } = this.state;
                        inputs.sms = this.state.inputs.sms == 0 ? 1 : 0;

                        this.setState({ inputs });
                      }}></input>
                    <small htmlFor='hk' className='font-weight-bold'>
                      {' '}
                      SMS
                    </small>
                  </label>

                  {/* <label className='mr-3 d-flex flex-row align-items-center'>
                    <input id='hk2' type='checkbox' className='mr-2'></input>
                    <small htmlFor='hk2' className='font-weight-bold'>
                      SMS
                    </small>
                  </label> */}
                </div>
              </div>
              <div className='text-center w-100 mt-4'>
                <button
                  className='btn btn-primary px-4 btn-round my-3'
                  disabled={this.state.pledged}>
                  Rejoindre SKY Girls
                </button>
              </div>
            </form>
          </div>

          <img src={flower1} className='flower flower-left' alt='' />
          <img src={flower2} className='flower flower-right' alt='' />
        </section>

        <Modal visible={this.state.showModal}>
          <div className='p-3 text-center card d-flex flex-column align-items-center'>
            <CheckCircle size={60} className='text-primary'></CheckCircle>

            <h2 className='my-3'>Thankyou</h2>
            <p className='my-3'>
              Congratulations you are now officially a SKY Girl. Remember to
              follow us on social to stay up to date with the cool things
              happening on SKY
            </p>

            <button
              className='btn btn-round btn-primary px-4'
              onClick={() => {
                this.setState({ showModal: false });
              }}>
              Ok
            </button>
          </div>
        </Modal>
      </>
    );
  }

  join = () => {
    // console.log(global.server);
    window
      .fetch(`${global.server}/pledges`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // this.setState({ showModal: true });
        window.location = '/thankyou';
      })
      .catch((err) => {});
  };
}

export default PledgeFormFR;
