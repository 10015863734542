import React, { Component } from 'react';

import youtubeLogo from '../../img/youtube_logo.png';
import { Play } from 'react-feather';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class DramaVideoPlaylist extends Component {
  state = {};
  render() {
    return (
      <section
        className={`${this.props.text_color}  ${this.props.background_color} ${this.props.className} position-relative bg-dark text-white drama-video py-5`}>
        <div className='text-center drama-video-playlist container'>
          <div className=''>
            <div className='d-flex flex-row play-list-text'>
              {this.props.title && (
                <div className='mr-3 dramatic-title'>{this.props.title}</div>
              )}

              {/* {this.props.link && (
                <Link href={this.props.link}>
                  <Play size={20}></Play> PLAY ALL
                </Link>
              )} */}
            </div>

            <div className='row align-items-start mt-4'>
              {this.props.videos.map((d, i) => {
                return (
                  <div
                    className={
                      '  video-item text-center mb-4 ' +
                      (this.props.className
                        ? 'col-md-4'
                        : 'col-md-3 col-6 px-3')
                    }
                    key={i}>
                    <a href={d.link} target='_blank text-center'>
                      <div className='video-container d-inline-block w-100'>
                        {d.image && (
                          <BannerComponent
                            value={d.image}
                            type={1}></BannerComponent>
                        )}

                        <img src={youtubeLogo} className='video-icon' alt='' />
                      </div>
                      <div className='text-left'>
                        <div className='mt-3 video-title text-white'>
                          {d.title}
                        </div>
                        {d.content && (
                          <div
                            className='mt-3 video-desc text-white'
                            dangerouslySetInnerHTML={{
                              __html: d.content,
                            }}></div>
                        )}
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }

  componentDidMount() {
    // console.log(this.props);
  }
}

export default DramaVideoPlaylist;
