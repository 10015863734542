import React, { Component } from 'react';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class ImageListItem extends Component {
  state = {};
  render() {
    return (
      <div>
        <section
          className={`${this.props.background_color} ${this.props.text_align}`}>
          <div className='my-md-5 container'>
            <div className='row my-5 align-items-center'>
              <div
                className={
                  'col-md-6 image-list-item ' +
                  (this.props.position == 1 && 'order-2 order-md-2')
                }>
                <div className=''>
                  <div
                    className={this.props.content_color}
                    dangerouslySetInnerHTML={{
                      __html: this.props.content,
                    }}></div>

                  {this.props.cta_button == 1 && (
                    <Link
                      to={this.props.button_link}
                      className='btn btn-primary px-4 btn-round my-3'>
                      {this.props.button_text}
                    </Link>
                  )}
                </div>
              </div>
              <div
                className={
                  'col-md-6 my-3 my-md-0 ' +
                  (this.props.position == 1 && 'order-1 order-md-1')
                }>
                {this.props.banner && (
                  <BannerComponent
                    value={this.props.banner}
                    type={this.props.banner_type}></BannerComponent>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ImageListItem;
