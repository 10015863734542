import React from 'react';
import Masonry from 'react-masonry-css';

const breakpointColumnsObj = {
  default: 4,
  1200: 3,
  992: 3,
  768: 2,
  576: 1,
};

function Gallery(props) {
  return (
    <section className={'py-5 gallery ' + props.className}>
      <div className='container'>
        <div className={props.className}>
          {/* create masonic grid */}

          <h1 className='text-white text-center mb-5 font-weight-bold chinese-rocks'>
            {props.content.title}
          </h1>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className='my-masonry-grid'
            columnClassName='my-masonry-grid_column'>
            {props.content.images &&
              props.content.images.map((d, i) => (
                <div key={i}>
                  <picture>
                    <source
                      media='(min-width: 1200px)'
                      srcSet={
                        typeof window !== 'undefined'
                          ? window.fileserver + d.name + '_512.webp'
                          : ''
                      }
                    />
                    <source
                      media='(min-width: 992px)'
                      srcSet={
                        typeof window !== 'undefined'
                          ? window.fileserver + d.name + '_512.webp'
                          : ''
                      }
                    />
                    <source
                      media='(min-width: 768px)'
                      srcSet={
                        typeof window !== 'undefined'
                          ? window.fileserver + d.name + '_512.webp'
                          : ''
                      }
                    />
                    <source
                      media='(min-width: 576px)'
                      srcSet={
                        typeof window !== 'undefined'
                          ? window.fileserver + d.name + '_512.webp'
                          : ''
                      }
                    />
                    <source
                      media='(min-width: 0px)'
                      srcSet={
                        typeof window !== 'undefined'
                          ? window.fileserver + d.name + '_512.webp'
                          : ''
                      }
                    />
                    <img
                      srcSet={
                        typeof window !== 'undefined'
                          ? window.fileserver + d.name + '_512.webp'
                          : ''
                      }
                      alt={d.alt}
                      className='img-fluid'
                    />
                  </picture>
                  <div className='mt-3'>
                    {d.alt && (
                      <div className='text-white font-weight-bold'>{d.alt}</div>
                    )}
                  </div>
                </div>
              ))}
          </Masonry>

          {/* list images in the media query sizes in picture element */}
        </div>
      </div>
    </section>
  );
}

export default Gallery;
