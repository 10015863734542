import React, { Component } from 'react';
import quote from '../../img/quote.png';

class Testimonials extends Component {
  state = {};
  render() {
    return (
      <section className='container py-5'>
        <div className='text-center'>
          <h1 className='intro-title'>{this.props.title}</h1>

          <p className='my-4 my-md-4'>{this.props.content}</p>
        </div>

        <div className='row justify-content-center'>
          {this.props.testimonials.map((d) => (
            <div className='col-md-6 my-5'>
              <div className='quote-card p-5'>
                <img src={quote} className='quote-icon' alt='' />

                <div
                  className='quote-content'
                  dangerouslySetInnerHTML={{
                    __html: d.content,
                  }}></div>
              </div>

              <div className='quote-user mt-4 text-center text-danger font-weight-bold'>
                {d.name}
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Testimonials;
