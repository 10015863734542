import React, { Component } from 'react';

class List extends Component {
  state = {};
  render() {
    return (
      <section
        className={`list-item my-5 bg-${this.props.background_color} ${this.props.className}`}>
        <div className='container '>
          <div className=''>
            <span className={'list-number ' + this.props.number_color}>
              {this.props.number}.
            </span>

            <div className=''>
              <span className={'list-title ' + this.props.title_color}>
                {this.props.title}
              </span>
              <div
                className={'list-content ' + this.props.content_color}
                dangerouslySetInnerHTML={{
                  __html: this.props.content,
                }}></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default List;
