import React, { Component } from 'react';

class AgePicker extends Component {
  state = { YYYY: '', MM: 1, DD: '', focused: 0 };
  render() {
    return (
      <div
        className={
          'age-picker ' + (this.props.className ? this.props.className : '')
        }>
        <div>
          <select
            name=''
            id=''
            className='form-control'
            required={this.props.required || true}
            onChange={(e) => {
              this.setState({ MM: e.target.value });
              this.update();
            }}>
            <option value='' selected hidden>
              Month
            </option>
            <option value='1'>January</option>
            <option value='2'>February</option>
            <option value='3'>March</option>
            <option value='4'>April</option>
            <option value='5'>May</option>
            <option value='6'>June</option>
            <option value='7'>July</option>
            <option value='8'>August</option>
            <option value='9'>September</option>
            <option value='10'>October</option>
            <option value='11'>November</option>
            <option value='12'>December</option>
          </select>
        </div>
        <div className='mx-2'>
          <input
            type='number'
            className='form-control day-input'
            value={this.state.DD}
            onChange={(e) => {
              this.setState({ DD: e.target.value });
              this.update();
            }}
            onFocus={() => this.focus(1)}
            onBlur={() => this.focus(-1)}
            placeholder='Day'
          />
        </div>

        <div>
          <input
            type='number'
            className='form-control year-input'
            value={this.state.YYYY}
            onChange={(e) => {
              this.setState({ YYYY: e.target.value });
              this.update();
            }}
            onFocus={() => this.focus(1)}
            onBlur={() => this.focus(-1)}
            placeholder='Year'
          />
        </div>
      </div>
    );
  }

  timeout = null;

  focus = (num) => {
    let { YYYY, MM, DD } = this.state;

    this.setState({ focused: this.state.focused + num });
    setTimeout(() => {
      if (this.props.onBlur && !this.state.focused) {
        var timestamp = Date.parse(`${YYYY}-${MM}-${DD}`);
        if (isNaN(timestamp)) {
          this.setState({ YYYY: '', DD: '' });
        } else {
          if (YYYY !== '' && DD !== '') {
            this.props.onBlur(`${YYYY}-${MM}-${DD}`);
          }
        }
      }
    }, 50);
  };

  update = () => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      let { YYYY, MM, DD } = this.state;
      if (this.props.onChange) this.props.onChange(`${YYYY}-${MM}-${DD}`);
    }, 500);
  };
}

export default AgePicker;
