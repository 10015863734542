import { Link } from 'gatsby';
import React, { Component } from 'react';
import BannerComponent from './bannerComponent';

class RecommendedArticles extends Component {
  state = {};

  render() {
    return (
      <section className=' magazine bg-secondary recommended-articles'>
        <div className='py-5 text-center container'>
          <h1 className='intro-title'>{this.props.title}</h1>

          <div className='magazine-cards mt-4'>
            {this.props.articles.map((d, i) => (
              <div className='col-md-3 mb-4' key={i}>
                <Link className='article-card w-100' to={'/article/' + d.id}>
                  <BannerComponent value={d.banner} type={1}></BannerComponent>
                  <div className='article-desc mt-3'>{d.title}</div>
                  <div className='text-light article-desc mt-1'>{d.date}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default RecommendedArticles;
