import React, { Component } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import Swiper from 'swiper/bundle';
import BannerComponent from './bannerComponent';

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

class Slider extends Component {
  uid = 400;

  state = {};
  render() {
    return (
      <div className={'py-4 bg-dark custom-slider ' + this.props.className}>
        <div className='container'>
          {this.props.title && (
            <div className='mr-3 dramatic-title mb-4 text-white play-list-text'>
              {this.props.title}
            </div>
          )}
          <div className='position-relative'>
            <div className={'drama-chevron chev-left chev-left' + this.uid}>
              <ChevronLeft size={18}></ChevronLeft>
            </div>
            <div className={'drama-chevron chev-right chev-right' + this.uid}>
              <ChevronRight size={18}></ChevronRight>
            </div>

            <div
              className={'swiper-container activ-' + this.uid}
              id={'activ-' + this.uid}>
              <div className='swiper-wrapper align-items-start'>
                {this.props.slides.map((d, i) => (
                  <div className='swiper-slide text-white' key={i}>
                    <div className='row align-items-center'>
                      <div
                        className={
                          'slider-image d-flex flex-row justify-content-center ' +
                          (this.props.slidesPerview >= 3
                            ? 'col-md-12'
                            : 'col-md-6')
                        }>
                        {' '}
                        <BannerComponent
                          value={d.image}
                          type={1}></BannerComponent>
                      </div>

                      <div
                        className={
                          'slider-image d-flex flex-row justify-content-center ' +
                          (this.props.slidesPerview >= 3
                            ? 'col-md-12'
                            : 'col-md-6')
                        }>
                        <div
                          className={`${this.props.content_color} ${this.props.content_font} text-content `}
                          dangerouslySetInnerHTML={{
                            __html: d.content,
                          }}></div>
                      </div>
                    </div>{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // console.log('slidesPerView', this.props.slidesPerview);

    this.swiper = new Swiper('#activ-' + this.uid, {
      direction: 'horizontal',
      mousewheel: false,
      keyboard: true,
      slidesPerView: this.props.slidesPerview / 2,
      initialSlide: 0,
      speed: 800,
      spaceBetween: 30,
      // centeredSlides: true,
      nested: false,
      loop: false,
      navigation: {
        nextEl: '.chev-right' + this.uid,
        prevEl: '.chev-left' + this.uid,
      },
      breakpoints: {
        480: {
          slidesPerView: this.props.slidesPerview,
        },
      },
    });

    console.log('swiper', this.swiper);
  }
}

export default Slider;
