import React, { Component } from 'react';
// icons
import values from '../../img/icons/values_Icon.svg';
import mission from '../../img/icons/mission_Icon.svg';
import vision from '../../img/icons/vision_Icon.svg';

class CompanyStatement extends Component {
  state = {};
  render() {
    return (
      <section className='container py-5'>
        <div className='row'>
          <div className='col-md-4 my-4 text-center'>
            <img className='about-icons mb-3' src={vision} alt='' />

            <h5 className='font-weight-bold text-pink'>
              {this.props.visionTitle}
            </h5>

            <p>{this.props.vision}</p>
          </div>

          <div className='col-md-4 my-4 text-center'>
            <img className='about-icons mb-3' src={mission} alt='' />

            <h5 className='font-weight-bold text-teal'>
              {this.props.missionTitle}
            </h5>

            <p>{this.props.mission}</p>
          </div>

          <div className='col-md-4 my-4 text-center'>
            <img className='about-icons mb-3' src={values} alt='' />

            <h5 className='font-weight-bold text-yellow'>
              {this.props.valuesTitle}
            </h5>

            <p>{this.props.values}</p>
          </div>
        </div>
      </section>
    );
  }
}

export default CompanyStatement;
