import React, { useState } from 'react';
import { useEffect } from 'react';
import BannerComponent from './bannerComponent';

import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation } from 'swiper/core';
import moment from 'moment/moment';
import Modal from '../modal';
import AgePicker from '../agePicker';

SwiperCore.use([Navigation]);

let swiper;

function SKYHub(props) {
  const [modalVisible, setModalVisible] = useState(false);

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [dob, setDob] = useState();
  const [location, setLocation] = useState();
  const [eventName, setEventName] = useState();
  const [eventDate, setEventDate] = useState();
  const [eventTime, setEventTime] = useState();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    new Swiper('.event-list-container', {
      speed: 500,
      spaceBetween: 10,
      navigation: {
        nextEl: '.nav-right',
        prevEl: '.nav-left',
      },
      breakpoints: {
        50: {
          slidesPerView: 1.2,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2.4,
        },
      },
    });

    new Swiper('.video-list-container', {
      speed: 500,
      spaceBetween: 20,
      navigation: {
        nextEl: '.nav-right',
        prevEl: '.nav-left',
      },

      breakpoints: {
        100: {
          slidesPerView: 1.5,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2.4,
        },
      },
    });
  }, [events]);

  let rsvp = () => {
    let inputs = {
      name,
      phone,
      dob,
      location,
      eventName,
      eventDate,
      eventTime,
    };

    // console.log(inputs);
    // return;

    window
      .fetch(`${global.server}/skyhub`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        window.location = '/thankyou2';
        // this.setState({ showModal: true });
      });
  };

  let fetchEvents = () => {
    // fetch events from today onwards

    window
      .fetch(
        `${window.server}/skyhub-events?date[$gte]=${moment()
          .startOf('day')
          .toISOString()}&status=1&$limit=20&$sort[date]=-1`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => response.json())
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className={'sky-hub-banner bg-milk'}>
        <BannerComponent
          value={props.content.banner}
          type={1}></BannerComponent>
      </div>

      <section className='bg-milk'>
        <div className='container py-5 bg-milk position-relative'>
          <div className='row align-items-center sky-hub-intro'>
            <div className='col-md-6'>
              <h1 className='text-purple chinese-rocks'>
                {props.content.title}
              </h1>

              <div className='mt-4'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.content.content,
                  }}></div>
              </div>
            </div>

            <div className='col-md-6'>
              <iframe
                title='Marsabit plaza'
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.165946710958!2d36.77293!3d-1.2999314!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd4f706c42ace746f!2sMarsabit%20plaza!5e0!3m2!1sen!2ske!4v1669616435021!5m2!1sen!2ske'
                height='300'
                className='border-0 w-100'
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-secondary'>
        <div className='containe '>
          <div className='row m-0 align-items-center'>
            <div className='col-md-4 text-right event-banner'>
              <div className='event-girl'>
                <BannerComponent
                  value={props.content.image}
                  type={1}></BannerComponent>
              </div>
            </div>

            <div className='col-md-8 px-3'>
              <div className=' py-4 pr-md-0'>
                <h1 className='text-purple mb-4 chinese-rocks'>
                  {props.content.event_title}
                </h1>

                <div class='swiper-container event-list-container'>
                  <div class='swiper-wrapper'>
                    {events.map((d, i) => (
                      <div class='swiper-slide' key={i}>
                        <div className='card p-3 shadow'>
                          <h5 className='text-primary font-weight-bold mb-3'>
                            {moment(d.date).format('dddd, Do MMMM')}
                          </h5>

                          {/* list events with list group */}
                          <div className='list-group'>
                            {d.events.map((d, i) => (
                              <div className='list-group-item' key={i}>
                                <div className='d-flex w-100 justify-content-between'>
                                  <div className='mb-1 pr-3'>{d.name}</div>
                                  <div className='font-weig-bold'>{d.time}</div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <small> {d.time}</small>

                          <p className='mt-3'>{d.description}</p>

                          <div className='text-right'>
                            {' '}
                            <button
                              className='link btn btn-primary'
                              onClick={() => {
                                setModalVisible(true);

                                setEventDate(d.date);
                                setEventName(d.title);
                                setEventTime(d.time);
                              }}>
                              R.S.V.P
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='py-5 sky-hub-videos'>
        <div className='container '>
          <h1 className='text-white text-center mb-5 font-weight-bold chinese-rocks'>
            {props.content.videos_title}
          </h1>

          <div className='row px-3'>
            <div class='swiper-container video-list-container'>
              <div class='swiper-wrapper'>
                {props.content.videos.map((d, i) => (
                  <div class='swiper-slide' key={i}>
                    <a href={d.link} target={'_blank'}>
                      <img src={d.thumbnail_url} alt='' />

                      <p className='my-3 font-weight-bold text-white'>
                        {d.title}
                      </p>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Modal visible={modalVisible} close={setModalVisible}>
        <div className=''>
          <h2 className='text-center chinese-rocks text-primary'>R.S.V.P</h2>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              rsvp();
            }}>
            <div className='my-3'>
              <label>Name</label>

              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type='text'
                className='form-control'
              />
            </div>
            <div className='my-3'>
              <label>Phone number</label>

              <input
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                type='tel'
                className='form-control'
              />
            </div>
            <div className='my-3'>
              <label>Date of Birth</label>

              <AgePicker
                onChange={(date) => {
                  setDob(date);
                }}
                onBlur={(date) => {}}></AgePicker>
            </div>
            <div className='my-3'>
              <label>Your Hood</label>

              <input
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                type='text'
                className='form-control'
              />
            </div>

            <div className='text-center'>
              <button className='btn btn-primary px-5'>Submit</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default SKYHub;
