import React, { Component } from 'react';
import BannerComponent from './bannerComponent';

class CustomDivider extends Component {
  state = { ...this.props };
  render() {
    return (
      <div className='divider my-5 d-flex flex-row justify-content-center'>
        <img
          src={require('../../images/' + this.props.banner)}
          className='partner-logo'
          alt=''
        />
      </div>
    );
  }
}

export default CustomDivider;
