import React, { Component } from 'react';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class Card extends Component {
  state = {};
  render() {
    return (
      <div
        className={
          'col-md-3 d-inline-block my-3 my-md-4 card-item ' +
          this.props.class_name
        }>
        <div className='card-image'>
          {this.props.banner && (
            <BannerComponent
              value={this.props.banner}
              type={this.props.banner_type}></BannerComponent>
          )}
        </div>

        <div className='mt-3 card-content'>
          <div
            className={this.props.content_color}
            dangerouslySetInnerHTML={{
              __html: this.props.content,
            }}></div>

          {this.props.cta_button == 1 && (
            <Link
              to={this.props.button_link}
              className='btn btn-primary px-4 btn-round my-3'>
              {this.props.button_text}
            </Link>
          )}
        </div>
      </div>
    );
  }
}

export default Card;
