import React, { Component } from 'react';
import BannerComponent from './bannerComponent';
import { Link } from 'gatsby';

class TextSectionVerical extends Component {
  state = {};
  render() {
    return (
      <div
        className={`${this.props.text_color} ${this.props.background_color} text-${this.props.text_align} pledge-section-vertical`}>
        <section className='container py-5'>
          <div className='text-cente'>
            <h1 className='intro-title'>{this.props.title}</h1>

            <div
              className='my-4'
              dangerouslySetInnerHTML={{
                __html: this.props.content,
              }}></div>

            {this.props.cta_button && (
              <Link
                to={this.props.button_link}
                className='btn btn-primary px-4 btn-round my-3'>
                {this.props.button_text}
              </Link>
            )}

            {this.props.banner && (
              <BannerComponent
                value={this.props.banner}
                type={this.props.banner_type}></BannerComponent>
            )}
            <div></div>
          </div>
        </section>
      </div>
    );
  }
}

export default TextSectionVerical;
