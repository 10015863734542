import React, { Component } from 'react';

class Partners extends Component {
  state = {};
  render() {
    return (
      <section
        className={`${this.props.text_color} ${this.props.background_color} ${this.props.className}`}>
        <div className='py-5 text-center container '>
          <h1 className='intro-title'>{this.props.title}</h1>
          <div className='font-weight-bol mt-4'>
            <p>{this.props.content}</p>

            <div className='row align-items-center'>
              {this.props.partners.map((d, i) => {
                return (
                  <div className='col-md-3 col-6 my-3 text-center' key={i}>
                    <a href={d.link} target='_blank'>
                      <img
                        src={require('../../images/' + d.logo)}
                        className='partner-logo'
                        alt=''
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Partners;
